import React from 'react'
import useToggler from './useToggler'

const Form = () => {
  const [isChecked, toggle] = useToggler(false)

  const buttonClass = isChecked ? 'button primary' : 'button primary unchecked'

  return (
    <form
      className="contact-form"
      id="contact-form"
      method="post"
      action="https://formspree.io/f/dicharryjerome@gmail.com"
    >
      <label htmlFor="_replyto">
        Email
        <input
          type="email"
          id="_replyto"
          name="_replyto"
          placeholder="Votre email"
          required
        />
      </label>
      <label htmlFor="name">
        Name
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Votre nom"
          required
        />
      </label>
      <label htmlFor="message">
        Message
        <textarea
          id="message"
          name="message"
          placeholder="Votre message"
          required
        />
      </label>
      <label htmlFor="toggler">
        <input
          type="checkbox"
          className="contact-form__acceptance"
          checked={isChecked}
          required
          name="toggler"
          id="toggler"
          onChange={toggle}
        />
        J'ai lu et accepte la politique de confidentialité de ce site
      </label>
      <button className={buttonClass} type="submit">
        ENVOYER
      </button>
    </form>
  )
}

export default Form

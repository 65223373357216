import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'
import Form from '../components/form'
import Cf7FormWrapper from '../components/cf7-form-wrapper'

const ContactPage = () => {
  return (
    <Layout title="Contactez un développeur Gatsby JS | Next JS | React JS à Strasbourg">
      <Head
        title="Contactez-moi"
        description="Contactez moi au 06 86 41 10 11 - Création de sites web sur mesure - Votre site internet clé en main, performant, responsive avec Gatsby JS ou Next JS"
      />
      <h2 className="mb-0 mt-section">
        Une question, un projet ? Contactez-moi
      </h2>
      <p>
        Par téléphone : <a href="tel:0686411011">06.86.41.10.11</a> ou via ce
        formulaire
      </p>
      <Cf7FormWrapper url="https://jeromedicharry.com/wp-json/contact-form-7/v1/contact-forms/441/feedback">
        <Form />
      </Cf7FormWrapper>

      <div className="mb-2">
        <div className="responsive-embed">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2639.0021192180784!2d7.751584115916721!3d48.59065827926223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796c9cd7147b815%3A0x5757801d27bb440e!2sJ%C3%A9r%C3%B4me%20Dicharry%20-%20D%C3%A9veloppeur%20web%20freelance!5e0!3m2!1sfr!2sfr!4v1600862427760!5m2!1sfr!2sfr"
            width="600"
            height="450"
            frameborder="0"
            allowfullscreen=""
            ariaHidden="false"
            // tabindex='0'
            title="Trouver Jérome Dicharry développeur web"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
